.subscribe-inline {
  box-sizing: border-box;
  @media only screen and (max-width: $tablet-breakdown) {
    width: 100%;
  }

  &__form {
    overflow: hidden;
    box-sizing: border-box;
    width: 430px;
    @media only screen and (max-width: $tablet-breakdown) {
      width: 100%;
    }
  }

  &__group {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    @media only screen and (max-width: $tablet-breakdown) {
      flex-direction: column;
    }
  }

  &__input {
    @extend %font-regular;
    display: block;
    width: 262px;
    height: 48px;
    border: none;
    padding: 0px 22px;
    line-height: 48px;
    outline: none;
    box-sizing: border-box;

    @media only screen and (max-width: $tablet-breakdown) {
      width: 100%;
      margin-bottom: 9px;
    }
  }

  &__btn {
    @extend %btn;
    width: 158px;
    height: 48px;
    box-sizing: border-box;
    @media only screen and (max-width: $tablet-breakdown) {
      width: 100%;
    }
  }

  &__title {
    @extend %font-regular;
    display: block;
    margin-bottom: 20px;
    @media only screen and (max-width: $tablet-breakdown) {
      text-align: center;
    }
  }



  &__result {
    @media only screen and (max-width: $tablet-breakdown) {
      text-align: center;
    }

    p {
      padding: 0;
      margin: 0;
      color: #FFFFFF;
    }
    a {
      color: #FFFFFF;
    }

    .subscribe__message--error {
      color: #FFFFFF;
      background: transparent;
    }
  }


}