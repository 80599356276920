.products-menu {
  margin-bottom: 40px;

  &__header {
    @extend %font-header;
    padding: 0;
    text-align: center;
    margin: 0 0 30px 0;
    @media only screen and (max-width: $mobile_breakdown) {
      margin: 36px 0 30px 0;
    }
  }

  &__items {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    @media only screen and (max-width: $tablet-breakdown) {
      justify-content: center;
      flex-wrap: wrap;
    }
  }

  &__item {
    @extend %font-regular;
    margin-right: 20px;
    &:last-child {
      margin-right: 0;
    }
  }
  &__link {
    @extend %link;
    padding-top: 0;

    &--active {
      span {
        &:after, &:before {
          width: 100%;
          opacity: 1;
        }
      }
    }

    &--highlight {
      color: #fc6b4c;
    }
  }
}
