.hamburger {

  width: 50px;
  height: 45px;
  transform: rotate(0deg);
  transition: .5s ease-in-out;
  cursor: pointer;

  span {
    display: block;
    position: absolute;
    height: 7px;
    width: 100%;
    background: #000000;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: .25s ease-in-out;

    &:nth-child(1) {
      top: 0px;
    }

    &:nth-child(2), &:nth-child(3) {
      top: 12px;
    }

    &:nth-child(4) {
      top: 24px;
    }
  }

  &.open {
    span {
      background: #FFFFFF;

      &:nth-child(1) {
        top: 12px;
        width: 0%;
        left: 50%;
      }
      &:nth-child(2) {
        transform: rotate(45deg);
      }
      &:nth-child(3) {
        transform: rotate(-45deg);
      }
      &:nth-child(4) {
        top: 12px;
        width: 0%;
        left: 50%;
      }
    }
  }
}