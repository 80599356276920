.about {
  margin-bottom: 55px;

  &__header, &__header-2, &__header-3 {
    @extend %font-header;
  }

  &__header-2 {
    font-size: 30px;
    margin-bottom: 25px;
  }

  &__header-3 {
    font-size: 25px;
    margin-bottom: 20px;
  }

  &__section {
    margin-bottom: 30px;
  }

  &__paragraph {
    margin: 20px 0;
    font-size: 22px;
    line-height: 30px;
  }

  &__img {
    width: 50%;
    height: auto;
    margin: 0 auto;
    display: block;
    border: none;

    @media only screen and (max-width: $mobile-breakdown) {
      width: 100%;
    }
  }
}

