.ugc-page {

  &__header {
    font-size: 1em;
    text-align: center;
    margin: 40px 0;
    font-weight: normal;
    color: #fc6b4c;
  }
}
