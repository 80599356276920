html {
  position: relative;
  min-height: 100%;

  @media only screen and (max-width: $mobile_breakdown) {
    position: inherit;
    min-height: inherit;
  }
}

body {
  @extend %font-regular;
  padding: 0;
  margin: 0;
  min-width: 320px;
  @media only screen and (max-width: $mobile_breakdown) {
    margin: 0 auto;
    &.open {
      height: 100%;
      overflow: hidden;
    }
  }
}

a {
  text-decoration: underline;
  color: #fc6b4c;
}

input {
  border-radius: 0;
}

.hide {
  &__tablet {
    display: inherit;
    @media only screen and (max-width: $tablet-breakdown) {
      display: none;
    }
  }
 }

.content {
  max-width: $content_width_web;
  min-width: 320px;
  width: 100%;
  margin: 64px auto 0;
  @media only screen and (max-width: $tablet-breakdown) {
    margin: 0;
    padding: 0 20px;
    box-sizing: border-box;
  }

  &--constructor {
    @media only screen and (max-width: $tablet-breakdown) {
      margin-top: 70px;
    }
  }
}

%btn {
  @extend %font-regular;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
  border: 2px solid #FFFFFF;
  background: transparent;
  transition: 0.2s ease;
  height: 46px;
  padding: 0 18px;
  text-decoration: none;

  &:hover {
    color: #000000;
    background: #FFFFFF;
  }
}

.btn {
  @extend %btn;

  &--orange {
    color: #fc6b4c;
    border: 2px solid #fc6b4c;

    &:hover {
      background: #fc6b4c;
      color: #FFFFFF;
    }
  }

  &--bg-orange {
    color: #FFFFFF;
    background: #fc6b4c;
    border: none;

    &:hover {
      color: #FFFFFF;
      background: #fc6b4c;
      opacity: 0.8;
    }
  }
}


$animate: all 0.2s ease-in-out;

%link {
  text-decoration: none;
  padding: 11px 0;
  display: block;
  color: #000000;

  span {
    margin: 0;
    padding: 0;
    transition: $animate;
    position: relative;
    text-transform: lowercase;

    &:before,
    &:after {
      content: "";
      position: absolute;
      bottom: -8px;
      width: 0;
      height: 3px;
      margin: 3px 0 0;
      transition: $animate;
      transition-duration: 0.5s;
      opacity: 0;
    }

    &:after {
      left: 0;
      background-color: #fc6b4c;
    }
  }

  &:hover {
    span {
      &:after, &:before {
        width: 100%;
        opacity: 1;
      }
    }
  }
}