@font-face {
  font-family: 'icons';
  src: url('./fonts/icomoon.woff') format('woff'),
  url('./fonts/icomoon.ttf') format('truetype'),
  url('./fonts/icomoon.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-email:before {
  content: "\e901";
}
.icon-telegram:before {
  content: "\e902";
}
.icon-instagram:before {
  content: "\e903";
}
.icon-download:before {
  content: "\e900";
}
.icon-menu:before {
  content: "\e9bd";
}
.icon-close:before {
  content: "\ea0f";
}

