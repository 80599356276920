.subscribe {
  position: fixed;
  z-index: 1000;
  bottom: -350px;
  opacity: 0;
  right: 0;
  min-width: 320px;
  width: 757px;
  min-height: 325px;
  padding: 45px;
  background: #ffffff;
  transition: bottom 0.5s ease-out;
  box-shadow: 10px 8px 27px 0 rgba(0, 0, 0, 0.32);
  text-align: center;
  box-sizing: border-box;

  &--open {
    opacity: 1;
    bottom: 0;
  }

  @media only screen and (max-width: $tablet-breakdown) {
    width: 80%;
  }

  @media only screen and (max-width: $mobile-breakdown) {
    width: 100%;
  }

  &__cols {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }

  &__col1 {
    background: url('/assets/subscribe.jpg');
    background-size: cover;
    margin-right: 30px;
    min-width: 245px;
    height: 245px;

    @media only screen and (max-width: $tablet-breakdown) {
      display: none;
    }
  }

  &__col2 {
    flex-grow: 1;
    height: 245px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media only screen and (max-width: $tablet-breakdown) {
      max-width: 100%;
    }
  }

  &__close {
    position: absolute;
    top: 22px;
    right: 14px;
    width: 16px;
    height: 16px;
    padding: 5px;
    background: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D%220%200%2024%2024%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Ctitle%3EFavicon%20Copy%202%3C%2Ftitle%3E%3Cg%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%3E%3Cpath%20d%3D%22M18.925%205.282L4.782%2019.425%22%20stroke%3D%22%234A4A4A%22%20stroke-width%3D%222%22%2F%3E%3Cpath%20d%3D%22M-.318.182h24v24h-24z%22%2F%3E%3Cpath%20d%3D%22M18.925%2019.425L4.782%205.282%22%20stroke%3D%22%234A4A4A%22%20stroke-width%3D%222%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E") no-repeat;
    background-size: 24px 24px;
    cursor: pointer;
  }

  &__header {
    @extend %font-header;
    margin-top: 0;
    line-height: initial;
    font-size: 20px;
    text-align: left;
  }

  &__text {
    @extend %font-regular;
    font-size: 16px;
    text-align: left;
    color: #000000;
  }

  &__form {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    width: 100%;
  }

  &__input {
    @extend %font-regular;
    height: 46px;
    width: 100%;
    padding: 0 20px;
    margin-right: 10px;
    border: 1px solid #dbdbdb;
    box-sizing: border-box;
  }

  &__submit {
    width: 155px;
    height: 46px;
    box-sizing: border-box;

    &--desktop {
      display: block;
      @media only screen and (max-width: $tablet-breakdown) {
        display: none;
      }
    }

    &--mobile {
      display: none;
      @media only screen and (max-width: $tablet-breakdown) {
        display: block;
      }
    }
  }

  &__result {
    display: flex;
    flex-direction: column;
    height: 250px;
    align-items: center;
    justify-content: center;

    &--hidden {
      display: none;
    }
  }

  &__message {
    @extend %font-regular;
  }

  &__action {
    &--hidden {
      display: none;
    }
  }
}