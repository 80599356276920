.everydaypractice {
  @media only screen and (max-width: $tablet-breakdown) {
    padding: 0 20px;
  }
  @media only screen and (max-width: $mobile_breakdown) {
    padding: 40px 20px 0 20px;
  }
  &__paragraph {
    margin: 20px 0;
    font-size: 22px;
    line-height: 30px;
  }

  &__link {
    display: flex;
    margin: 0 auto;
    max-width: 200px;
    text-align: center;
    text-decoration: none;
    justify-content: center;

    &:visited {
      color: #FFFFFF;
    }
  }

  &__photos {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__photo {
    max-width: 800px;
    width: 100%;
    box-sizing: border-box;
    padding: 10px;
  }

  &__image {
    width: 100%;
    height: auto;
  }
}

