/* fonts */

@font-face {
  font-family: mabry-regular-pro;
  src: url('./fonts/mabry-regular-pro.eot');
  src: url('./fonts/mabry-regular-pro.eot?#iefix') format('embedded-opentype'),
  url('./fonts/mabry-regular-pro.woff') format('woff'),
  url('./fonts/mabry-regular-pro.woff2') format('woff2'),
  url('./fonts/mabry-regular-pro.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: mabry-bold-pro;
  src: url('./fonts/mabry-bold-pro.eot');
  src: url('./fonts/mabry-bold-pro.eot?#iefix') format('embedded-opentype'),
  url('./fonts/mabry-bold-pro.woff') format('woff'),
  url('./fonts/mabry-bold-pro.woff2') format('woff2'),
  url('./fonts/mabry-bold-pro.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;

}

$regular: mabry-regular-pro, Arial, sans-serif;
$bold: mabry-bold-pro, Arial, sans-serif;

%font-regular {
  font-family: $regular;
  font-size: 20px;
}

%font-banner {
  font-family: $bold;
  font-size: 38px;
  line-height: 45px;
  @media only screen and (max-width: $mobile_breakdown) {
    font-size: 23px;
    line-height: 30px;
  }
}

%font-header {
  font-family: $bold;
  font-size: 50px;
  line-height: 50px;
  @media only screen and (max-width: $mobile_breakdown) {
    font-size: 28px;
    line-height: initial;
  }
}