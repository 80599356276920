.instagram {

  &__social {
    background: #fc6b4c;
    color: #FFFFFF;
    height: 254px;
    width: 100%;
    margin: 0;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
  }

  &__text {
    margin-bottom: 14px;
    &:last-child {
      margin-bottom: 0;
    }
    @media only screen and (max-width: $tablet-breakdown) {
      text-align: center;
    }

    &--center {
      text-align: center;
    }
  }

  &__link {
    color: #FFFFFF;
    display: inline-block;
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }

  &__wrapper {
    position: relative;
    left: 0;
    right: 0;
    height: 500px;
    overflow: hidden;
    iframe {
      width: 2500px;
      height: 500px;
    }
  }
}