@import "constants";

.error {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 200px;
  flex-direction: column;

  @media only screen and (max-width: $mobile_breakdown) {
    padding: 0 10px;
    box-sizing: border-box;
  }
}