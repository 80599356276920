.product-item {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-bottom: 55px;

  @media only screen and (max-width: $mobile_breakdown) {
    flex-direction: column;
  }

  &__photos {
    flex-grow: 1;
    margin-right: 30px;
    @media only screen and (max-width: $mobile_breakdown) {
      margin: 0 auto;
      flex: 0 0 80%;
      max-width: 80%;
    }
  }

  &__photo {
    width: 100%;
    height: auto;

    &--border {
      //border: 1px solid #ccc;
      box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.3);
    }
  }

  &__metadata {
    flex: 0 0 60%;
    @media only screen and (max-width: $mobile_breakdown) {
      flex: 0 0 100%;
      margin: 20px auto;
      padding: 0 20px;
    }
  }

  &__title {
    margin-top: 0;

    @media only screen and (max-width: $mobile_breakdown) {
      text-align: center;
    }
  }

  &__download {
    text-decoration: none;
    text-align: center;
    box-sizing: border-box;
    min-width: 300px;
    width: 50%;
    margin-right: 10px;
    @media only screen and (max-width: $mobile_breakdown) {
      margin: 10px 0;
      min-width: 100%;
      width: 100%;
    }
  }

  &__icon {
    display: inline-block;
    vertical-align: center;
    font-size: 0.7em;
    background-size: 24px 24px;
    background-repeat: no-repeat;
    padding-left: 30px;
    margin-top: 10px;
    height: 24px;
    line-height: 24px;
    margin-right: 10px;

    @media only screen and (max-width: $mobile_breakdown) {
      margin-left: 0;
      margin-top: 10px;
    }

    &--pageViews {
      background-image: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="%23fc5934" d="M12.015 7c4.751 0 8.063 3.012 9.504 4.636-1.401 1.837-4.713 5.364-9.504 5.364-4.42 0-7.93-3.536-9.478-5.407 1.493-1.647 4.817-4.593 9.478-4.593zm0-2c-7.569 0-12.015 6.551-12.015 6.551s4.835 7.449 12.015 7.449c7.733 0 11.985-7.449 11.985-7.449s-4.291-6.551-11.985-6.551zm-.015 5c1.103 0 2 .897 2 2s-.897 2-2 2-2-.897-2-2 .897-2 2-2zm0-2c-2.209 0-4 1.792-4 4 0 2.209 1.791 4 4 4s4-1.791 4-4c0-2.208-1.791-4-4-4z"/></svg>');
    }

    &--downloads {
      background-image: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 55.012 55.012" width="512" height="512"><path d="M30.009 41.008v-19h-4v19l-6-5-3 3 11 10 10-10-3-3-5 5zM45.207 19.9c-1.17-5.648-4.598-8.892-9.199-8.892-1.319 0-2.879.308-4.333.852-3.163-4.176-6.8-5.856-10.205-5.856-6.825 0-12.722 6.746-12.028 13.67.021.211.025.412.032.621-4.453.977-9.716 5.574-9.465 10.713.251 5.139 3.761 10.955 9.399 10.955l3.601.045v-4l-3.601-.037c-3.541 0-5.27-4.319-5.399-6.963-.146-2.989 3.551-6.205 6.327-6.814l3.252-.714-.11-3.316-.003-.084c-.008-.268-.019-.53-.047-.803-.224-2.233.647-4.672 2.33-6.525 1.591-1.751 3.672-2.756 5.712-2.756 4.253 0 6.823 4.024 8.812 6.649l2.802-1.048c4.391-1.642 7.24.474 8.2 5.109l.725 3.301h3c2.685 0 6.092 2.547 6 6-.105 3.925-3.766 7.963-7.226 7.963l-1.774.037v4l1.774-.045c5.931 0 11.072-6.196 11.226-11.955.153-5.759-4.922-10.107-9.802-10.107z" fill="%23fc5934"/></svg>');
    }
  }

  &__note {
    color: #999;
    font-size: 12px;
  }

  &__warning {
    margin-top: 2em;

    p {
      margin: 0 0 1em 0;
    }
  }

  &__warning-row {
    display: inline-block;
    line-height: 24px;
    background: no-repeat left top;
    background-size: 24px 24px;
    padding-left: 30px;


    &--ok {
      background-image: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 400 400"><path d="M199.996 0C89.713 0 0 89.72 0 200s89.713 200 199.996 200S400 310.28 400 200 310.279 0 199.996 0zm0 373.77C104.18 373.77 26.23 295.816 26.23 200c0-95.817 77.949-173.769 173.766-173.769S373.767 104.184 373.767 200c.001 95.816-77.955 173.77-173.771 173.77z"/><path d="M272.406 134.526L169.275 237.652l-41.689-41.68c-5.123-5.117-13.422-5.12-18.545.003-5.125 5.125-5.125 13.425 0 18.548l50.963 50.955c2.561 2.558 5.916 3.838 9.271 3.838s6.719-1.28 9.279-3.842c.008-.011.014-.022.027-.035L290.95 153.071c5.125-5.12 5.125-13.426 0-18.546-5.122-5.123-13.427-5.123-18.544.001z"/></svg>');
    }

    &--stop {
      background-image: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 400 400"><path d="M199.992 0C89.717 0 0 89.72 0 200s89.717 200 199.992 200C310.273 400 400 310.28 400 200S310.273 0 199.992 0zm0 373.77C104.178 373.77 26.23 295.816 26.23 200c0-95.817 77.947-173.769 173.762-173.769 95.822 0 173.776 77.953 173.776 173.769.001 95.816-77.954 173.77-173.776 173.77z"/><path d="M265.473 134.529c-5.123-5.123-13.422-5.123-18.545 0L200 181.454l-46.928-46.925c-5.123-5.123-13.424-5.123-18.547 0-5.123 5.12-5.123 13.426 0 18.546L181.447 200l-46.928 46.926c-5.123 5.119-5.123 13.422 0 18.547 2.561 2.562 5.918 3.842 9.273 3.842s6.711-1.28 9.271-3.842L200 218.543l46.928 46.932a13.068 13.068 0 0 0 9.271 3.842c3.354 0 6.711-1.279 9.272-3.842 5.123-5.119 5.123-13.426 0-18.545L218.545 200l46.928-46.925c5.123-5.12 5.123-13.423 0-18.546z"/></svg>');
    }
  }

}
