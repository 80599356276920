.products {
  &__list {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
}

.product {
  display: inline-block;
  width: 313px;
  margin: 0 0 55px 0;

  &:nth-child(3n + 4), &:first-child {
    margin-right: 30px;
  }

  &:nth-child(3n) {
    margin-left: 30px;
  }

  @media only screen and (max-width: $tablet-breakdown) {
    width: calc((100% - 30px) / 2);
    &:nth-child(3n + 4), &:first-child {
      margin-right: 0;
    }
    &:nth-child(3n) {
      margin-left: 0;
    }
    &:nth-child(even) {
      margin-left: 30px;
    }
  }

  @media only screen and (max-width: $mobile_breakdown) {
    width: 100%;
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  &__link {
    color: $links_color;
    display: block;
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }

  &__photo {
    transition: opacity ease .3s;
    width: 100%;
    height: auto;

    &:hover {
      opacity: 0.5;
    }

    &--border {
      //box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.3);
      border: 1px solid #cccccc;
    }
  }

  &__description {
    @extend %font-regular;
    color: #cfcfcf;
    text-align: center;
    margin: 20px 0 0 0;
  }

  &__constructor {
    text-align: center;
  }

  &__highlight {
    background: #e96848;
    margin: 1px auto 0 auto;
    padding: 12px;
    color: #fff;
    text-align: center;
    font-size: 22px;
    display: inline-block;
  }

}
