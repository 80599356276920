.header {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;

  &--open, &--search {
    background: #000000;
    z-index: 9999;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &__wrapper {
    max-width: 1200px;
    min-width: 320px;
    width: 100%;
    height: 100px;
    overflow: hidden;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;

    @media only screen and (max-width: $tablet-breakdown) {
      height: 70px;
      padding: 0 20px;
      box-sizing: border-box;

      .header--open &, .header--search & {
        position: fixed;
        top: 0;
      }
    }
    @media only screen and (max-width: $mobile_breakdown) {
      overflow: hidden;
      z-index: 70;
      transition: 0.5s;
    }
  }

  &__logo {
    text-indent: -99999px;
    background: url(./icons/365-logo-black.svg) no-repeat;
    background-size: contain;
    height: 27px;
    margin: 0;
    width: 146px;

    &:hover {
      text-decoration: none;
      opacity: 0.7;
    }

    .header--open &, .header--search & {
      background: url(./icons/365-logo-white.svg) no-repeat;
    }
  }

  &__menu {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;

    @media only screen and (max-width: $tablet-breakdown) {
      display: none;
    }
  }

  &__menu-item {
    margin-right: 25px;

    &:last-child {
      margin-right: 0;
    }
  }

  &__menu-link {
    @extend %link;

    &--active {
      span {
        &:after, &:before {
          width: 100%;
          opacity: 1;
        }
      }
    }
  }

  &__search {
    width: 167px;
    border: 1px solid #dbdbdb;

    @media only screen and (max-width: $tablet-breakdown) {
      display: none;
    }

  }

  &__search-label {
    position: relative;

    &:before {
      content: "";
      position: absolute;
      left: 9px;
      top: 0;
      bottom: 0;
      width: 16px;
      background: url("./icons/search-icon.svg") center / contain no-repeat;
    }
  }

  &__search-input {
    @extend %font-regular;
    font-size: 18px;
    box-sizing: border-box;
    padding: 6px 10px 6px 32px;
    width: 100%;
    border: none;
    background-color: transparent;
    color: #a9a9a9;
  }


  &__mobile-actions {
    display: none;
    @media only screen and (max-width: $tablet-breakdown) {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      height: 35px;

      .header--open &, .header--search & {
        display: none;
      }
    }
  }

  &__mobile-divider {
    display: block;
    height: 100%;
    width: 1px;
    background: #cccccc;
  }

  &__mobile-btn {
    display: block;
    width: 24px;
    height: 24px;
    background-size: cover;
    background-position: center;

    &--burger {
      background-image: url("./icons/menu-burger.svg");
      margin-left: 15px;
      width: 28px;
      height: 19px;
    }

    &--search {
      background-image: url("./icons/search-icon-black.svg");
      margin-right: 15px;
    }
  }

  &__mobile-close {
    display: none;
    width: 22px;
    height: 22px;
    background-size: cover;
    background-position: center;
    background-image: url("./icons/close.svg");

    @media only screen and (max-width: $tablet-breakdown) {
      .header--open &, .header--search & {
        display: block;
      }
    }
  }
}

.mobile-menu {
  display: none;
  flex-direction: column;
  background: #000000;
  position: fixed;
  top: 70px;
  bottom: 0;
  left: 0;
  right: 0;
  color: #FFFFFF;
  align-items: center;
  justify-content: center;

  &--menu {
    @media only screen and (max-width: $tablet-breakdown) {
      .header--open & {
        display: flex;
      }
    }
  }

  &--search {
    @media only screen and (max-width: $tablet-breakdown) {
      .header--search & {
        display: flex;
      }
    }
  }

  &__list {
    list-style: none;
    text-align: center;
    padding: 0;
    margin: 0 0 50px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__item {
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__link {
    color: #FFFFFF;
    text-decoration: none;
  }

  &__search {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }

  &__input {
    @extend %font-regular;
    flex-grow: 1;
    height: 48px;
    line-height: 48px;
    padding: 0 10px;
    box-sizing: border-box;
    margin-right: 10px;
  }

  &__submit {
    @extend %btn;
    height: 48px;
  }
}

