.banner {
  height: 464px;
  @extend %font-banner;
  color: #FFFFFF;

  @media only screen and (max-width: $mobile-breakdown) {
    height: 314px;
  }

  &__container {
    height: 464px;
    @media only screen and (max-width: $mobile-breakdown) {
      height: 314px;
    }
  }

  &__slide {
    position: relative;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-grow: 1;
    align-items: center;
    height: 400px;
    @media only screen and (max-width: $mobile-breakdown) {
      height: 250px;
    }

    @media only screen and (max-width: $mobile-breakdown) {
      &:after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: block;
        background-color: rgba(0, 0, 0, 0.2);
      }
    }

    &--dskt-bottom-left {
      background-position: left bottom;

      @media only screen and (max-width: $mobile-breakdown) {
        background-position: right center;
      }  
    }
  }

  &__slide-content {
    position: relative;
    z-index: 100;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-left: 99px;

    @media only screen and (max-width: $mobile-breakdown) {
      width: 100%;
      margin: 0 44px;
      align-items: center;
    }
  }

  &__slide-text {
    text-align: left;

    @media only screen and (max-width: $mobile-breakdown) {
      text-align: center;
    }

    &--black {
      color: #000000;

      @media only screen and (max-width: $mobile-breakdown) {
        color: #FFFFFF;
      }
    }
  }

  &__slide-note {
    @extend %font-regular;
    padding-top: 24px;
    line-height: 125%;
    max-width: 50%;
  }

  &__slide-action {
    margin-top: 30px;
  }

  &__slide-btn {
    @extend %btn;
    text-decoration: none;
    width: 130px;
  }

  &__prev-btn {
    background-image: url('./icons/banner-arrow-left.svg');
    left: 30px;
    top: calc(50% - 32px);
    width: 30px;
    background-size: contain;
    @media only screen and (max-width: $mobile-breakdown) {
      width: 24px;
      height: 24px;
      left: 20px;
    }
  }

  &__next-btn {
    background-image: url('./icons/banner-arrow-right.svg');
    right: 30px;
    top: calc(50% - 32px);
    width: 30px;
    background-size: contain;
    @media only screen and (max-width: $mobile-breakdown) {
      width: 24px;
      height: 24px;
      right: 20px;
    }
  }

  &__pagination {
    bottom: 0 !important;
    height: 64px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;

    .swiper-pagination-bullet {
      opacity: 1;
      background: #d8d8d8;
    }

    .swiper-pagination-bullet-active {
      background: #494949;
    }
  }

}