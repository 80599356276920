.patreon {
  background: #ffffc4;
  color: #000000;
  font-size: 28px;

  @media only screen and (max-width: $tablet-breakdown) {
    font-size: inherit;
  }

  &__wrapper {
    height: 254px;
    width: 90%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
  }

  &__text {
    margin-bottom: 14px;
    text-align: center;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__link {
    color: #000000;
    display: inline-block;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}