@import "constants";

/* popup */
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  overflow: hidden;
  opacity: 0;
  display: none;

  align-items: center;
  justify-content: center;

  &.show {
    display: flex;
    opacity: 1;
  }

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000000;
    z-index: 90;
    overflow: hidden;
    display: none;
    pointer-events: none;
    opacity: 0.85;
  }

  &__close {
    position: absolute;
    width: 25px;
    height: 26px;
    top: -35px;
    right: -35px;
    text-decoration: none;
    color: #FFFFFF;
    background-image: url('../scss/icons/close.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;

    &:hover {
      opacity: 0.6;
    }

    @media only screen and (max-width: $mobile_breakdown) {
      right: -5px;
    }
  }

  &__content {
    text-align: center;
    padding: 40px;
    background: #FFFFFF;
    position: relative;
    width: 50%;
    box-sizing: border-box;
    z-index: 100;

    @media only screen and (max-width: $tablet-breakdown) {
      width: 70%;
    }

    @media only screen and (max-width: $mobile_breakdown) {
      width: 90%;
      padding: 10px 20px;
    }
  }
}

.popup-success {

  @media only screen and (max-width: $mobile_breakdown) {
    padding: 30px 0;
  }

  &__btn {
    max-width: 200px;
    margin: 0 auto;
    text-decoration: none;
  }

  &__text {
    color: #000000;
  }
}

.popup-download {

  sup {
    font-family: Arial, sans-serif;
  }

  &__header {
    margin: 0 0 20px 0;
  }

  &__divider {
    margin: 20px 0;
  }

  &__share-items {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-items: center;
    justify-content: center;
    list-style: none;

    @media only screen and (max-width: $mobile_breakdown) {
      flex-direction: column;
    }

  }

  &__share-item {
    margin-right: 10px;
    @media only screen and (max-width: $mobile_breakdown) {
      width: 100%;
      margin-right: 0;
      margin-bottom: 10px;
    }
  }

  &__btn {
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    color: #FFFFFF;
    text-decoration: none;
    transition: all .5s;

    padding: 0 20px;
    width: auto;
    height: 48px;

    &--facebook {
      border-color: #3b5998;
      background: #3b5998;
    }

    &--vk {
      border-color: #507299;
      background: #507299;
    }

    &--pt {
      border-color: #e60023;
      background: #e60023;
    }

    &:hover {
      opacity: .8;
    }
  }

  &__note {
    color: #999;
    font-size: 12px;
  }

  &__agreement {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 0 auto;
  }

  &__payment {
    margin-bottom: 5px;
  }

  &__checkbox {
    margin-right: 7px;
  }

  &__email-field {
    padding: 0;
    border: none;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    width: 100%;
  }

  &__payment-btn {
    height: 48px;
    width: 100px;
  }

  &__email-input {
    @extend %font-regular;
    width: 50%;
    height: 48px;
    padding: 0 10px;
    margin: 0 10px 0 0;
    box-sizing: border-box;
    border: 2px solid #fc6b4c;
    outline: none;
    @media only screen and (max-width: $tablet-breakdown) {
      width: 100%;
    }
  }
}

