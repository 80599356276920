.footer {
  overflow: hidden;
  background: #000000;
  color: #FFFFFF;
  height: 413px;
  width: 100%;
  box-sizing: border-box;

  @media only screen and (max-width: $tablet-breakdown) {
    height: auto;
  }

  &__wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    max-width: 1000px;
    min-width: 320px;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    box-sizing: border-box;

    @media only screen and (max-width: $tablet-breakdown) {
      flex-direction: column;
      box-sizing: border-box;
      padding: 0 20px;
    }
  }

  &__col {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 63px 0 105px 0;
    box-sizing: border-box;
    justify-content: space-between;

    &--left {
      width: 686px;
    }

    @media only screen and (max-width: $tablet-breakdown) {
      width: 100%;
      height: 380px;
      align-items: center;
      padding: 60px 0 84px 0;

      &--left {
        padding-top: 50px;
        padding-bottom: 0;
        width: 100%;
        height: auto;
      }
    }
  }

  &__logo {
    background: url('../scss/icons/365-logo-white.svg') no-repeat left center;
    background-size: contain;
    width: 156px;
    height: 26px;

    @media only screen and (max-width: $tablet-breakdown) {
      margin-bottom: 55px;
    }
  }

  &__copyright {
    color: #686868;
    @media only screen and (max-width: $tablet-breakdown) {
      display: none;
    }
  }

  &__menu {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &__menu-item {
    margin-bottom: 8px;
    &:last-child {
      margin-bottom: 0;
    }

    @media only screen and (max-width: $tablet-breakdown) {
      text-align: center;
    }
  }

  &__menu-link {
    color: #FFFFFF;
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }

  &__social {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
  }

  &__social-link {
    height: 32px;
    width: 32px;
    display: flex;
    font-size: 14px;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #333;
    margin-right: 10px;
    background-size: contain;
    text-decoration: none;
    color: #999999;

    &:hover {
      color: #FFFFFF;
    }
  }
}